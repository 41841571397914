import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.logoChange()
        NavbarComponent.sticky()

        // Initialize based on current viewport
        if (window.matchMedia('(max-width: 1000px)').matches) {
            NavbarComponent.mediumHandler()
        } else {
            NavbarComponent.desktopHandler()
        }
    }

    static logoChange() {
        if (
            ($('body.home').length > 0 && $('.navbar--onscroll').length === 0) ||
            $('body.error404').length > 0
        ) {
            NavbarComponent.changeLogoColor('banner')
        }

        if (window.matchMedia('(min-width: 1001px)').matches) {
            $('.menu__item.has-children')
                .on('mouseenter', () => {
                    NavbarComponent.changeLogoColor('classic')

                    if (NavbarComponent.timeoutId) {
                        clearTimeout(NavbarComponent.timeoutId)
                        NavbarComponent.timeoutId = undefined
                    }
                })
                .on('mouseleave', () => {
                    if (!NavbarComponent.timeoutId) {
                        NavbarComponent.timeoutId = setTimeout(() => {
                            if (
                                ($('.navbar').hasClass('navbar--ontop') &&
                                    $('.has-banner').length > 0) ||
                                $('.error404').length > 0
                            ) {
                                NavbarComponent.changeLogoColor('banner')
                                NavbarComponent.timeoutId = undefined
                            }
                        }, 500)
                    }
                })
        }
    }

    static sticky() {
        if ($(NavbarEnum.BODY).hasClass('navbar-disable-sticky')) {
            return
        }

        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        const logoBanner = IRISCollectionCustomer.imageLogoBanner // white
        const logoClassic = IRISCollectionCustomer.imageLogo // black

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        // Clean up any existing handlers first
        $(NavbarEnum.BURGER_MENU_TRIGGER).off('click')
        $(NavbarEnum.BURGER_MENU_MORE).off('click')
        $(NavbarEnum.BURGER_MENU_PREV).off('click')

        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 1000px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('classic')
                } else {
                    if (
                        $('.navbar--onscroll').length > 0 ||
                        !($('.navbar--ontop').length > 0 && $('.has-banner').length > 0) ||
                        $('body.error404').length > 0
                    ) {
                        NavbarComponent.changeLogoColor('classic')
                    } else {
                        NavbarComponent.changeLogoColor('banner')
                    }
                }
            }

            // Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function (e) {
            e.preventDefault()
            $(this).next(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
        })
    }

    static cleanupBurgerHandlers() {
        $(NavbarEnum.BURGER_MENU_TRIGGER).off('click')
        $(NavbarEnum.BURGER_MENU_MORE).off('click')
        $(NavbarEnum.BURGER_MENU_PREV).off('click')
        $(NavbarEnum.BODY).removeClass('menu-open')
        $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
    }

    static desktopHandler() {
        // Clean up mobile menu handlers and state
        NavbarComponent.cleanupBurgerHandlers()
        const burger = document.querySelector('[data-element="burger"]')
        if (burger?.classList.contains('is-active')) {
            burger.classList.remove('is-active')
            document.querySelector('[data-element="menu"]').classList.remove('is-active')
        }

        NavbarComponent.setupDesktopMenuHandlers()

        // When we're not on desktop anymore
        window.addEventListener('resize', function mediumOnly() {
            if (window.matchMedia('(max-width: 1000px)').matches) {
                NavbarComponent.mediumHandler()
                window.removeEventListener('resize', mediumOnly)
            }
        })
    }

    static setupDesktopMenuHandlers() {
        const menuItems = document.querySelectorAll('.menu__item.has-children')
        menuItems.forEach((item) => {
            if (!item.hasDesktopHandlers) {
                item.addEventListener('mouseenter', () => {
                    NavbarComponent.changeLogoColor('classic')
                    if (NavbarComponent.timeoutId) {
                        clearTimeout(NavbarComponent.timeoutId)
                        NavbarComponent.timeoutId = undefined
                    }
                })

                item.addEventListener('mouseleave', () => {
                    if (!NavbarComponent.timeoutId) {
                        NavbarComponent.timeoutId = setTimeout(() => {
                            if (
                                ($('.navbar').hasClass('navbar--ontop') &&
                                    $('.has-banner').length > 0) ||
                                $('.error404').length > 0
                            ) {
                                NavbarComponent.changeLogoColor('banner')
                                NavbarComponent.timeoutId = undefined
                            }
                        }, 500)
                    }
                })

                item.hasDesktopHandlers = true
            }
        })
    }

    static mediumHandler() {
        // Clean up desktop handlers first
        const menuItems = document.querySelectorAll('.menu__item.has-children')
        menuItems.forEach((item) => {
            item.hasDesktopHandlers = false
        })

        NavbarComponent.burger()

        // When we're not on medium anymore
        window.addEventListener('resize', function desktopOnly() {
            if (window.matchMedia('(min-width: 1001px)').matches) {
                NavbarComponent.desktopHandler()
                window.removeEventListener('resize', desktopOnly)
            }
        })
    }
}
